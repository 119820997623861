import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  TextField,
  CircularProgress
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { GoogleOAuthProvider, GoogleLogin, googleLogout } from '@react-oauth/google';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,   // WhatsApp Share Button
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  TumblrIcon,
  RedditIcon,
} from 'react-share';
import LandingPage from './components/LandingPage';
import './App.css';
import emailjs from 'emailjs-com';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import axios from 'axios';
import config from './config';  
import DeleteIcon from '@mui/icons-material/Delete';
import { loadStripe } from '@stripe/stripe-js';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4a90e2',  // Main primary color for elements like buttons
      dark: '#3a78c2',  // A slightly darker blue shade for the AppBar
    },
    secondary: {
      main: '#50b6c5',  // Lighter blue shade for secondary elements
    },
    background: {
      default: '#f0f8ff',  // Light blue background color
    },
  },
  fontFamily: 'Finger Paint, Roboto, sans-serif', // Apply Finger Paint along with fallback fonts
    h6: {
      fontFamily: 'Finger Paint, Roboto, sans-serif', // Specifically target h6 headers if needed
    },
});

const ProfileDialog = ({ open, onClose, userName, userPicture, userEmail }) => {
  const [userImages, setUserImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserImages = async () => {
        if (open && userEmail) {
            setLoading(true);
            try {
                const response = await axios.get(`${config.backendUrl}/get_user_images`, {
                    params: { email: userEmail },
                });

                // Debugging: Print the response to check what is fetched
                console.log('Fetched images:', response.data);  

                // Ensure the response is valid and contains images
                if (response.data && response.data.images) {
                    setUserImages(response.data.images);
                } else {
                    setUserImages([]);
                }
            } catch (error) {
                console.error('Error fetching user images:', error);
                setUserImages([]);
            } finally {
                setLoading(false);
            }
        }
    };

      fetchUserImages();
  }, [open, userEmail]);

  const handleImageClick = (imageSrc) => {
    const link = document.createElement('a');
    link.href = imageSrc;
    link.download = 'coloring_page.png';
    link.click();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '20px',
          padding: '32px',
          textAlign: 'center',
          maxWidth: '540px',
          minWidth: '540px',
          margin: 'auto',
          overflow: 'hidden',
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#999',
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Avatar
          alt={userName}
          src={userPicture}
          sx={{
            width: 90,
            height: 90,
            border: '3px solid white',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
          }}
        />
        <Typography variant="h6" sx={{ mt: 2, color: '#3a78c2', fontWeight: 600 }}>
          {userName}
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ fontWeight: 400, color: '#666', mb: 3 }}>
        Your Coloring Pages:
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: 3,
          maxHeight: '280px',
          overflowY: 'scroll',
          paddingRight: '1px',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ccc',
            borderRadius: '4px',
          },
          position: 'relative',
          paddingBottom: '16px',
        }}
      >
        {loading ? (
          <Typography variant="body2">Loading...</Typography>
        ) : (
          userImages.map((image, index) => (
            <Box
              key={index}
              onClick={() => handleImageClick(`data:image/png;base64,${image.image_base64}`)}
              sx={{
                width: 120,
                height: 120,
                borderRadius: '12px',
                overflow: 'hidden',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s, box-shadow 0.3s',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <img
                src={`data:image/png;base64,${image.image_base64_watermark || image.image_base64}`}  // Show watermark if available
                alt={`User upload ${index + 1}`}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          ))
        )}
      </Box>
    </Dialog>
  );
};

const ContactUsDialog = ({ open, onClose, userEmail }) => {
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [error, setError] = useState('');

  const handleSendMessage = () => {
    if (!message.trim()) {
      setError('Message cannot be empty.');
      return;
    }

    setSending(true);
    emailjs
      .send(
        'service_0lg76nm',
        'template_uzfjdcl',
        { email: userEmail, message },
        'hcmgW9RkX1HmiG4SF'
      )
      .then(() => {
        setSending(false);
        setMessage('');
        setError('');  // Clear any previous errors
        onClose();
      })
      .catch((err) => {
        setSending(false);
        setError('Failed to send message. Please try again.');
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '20px',
          padding: '24px',
          textAlign: 'center',
          maxWidth: '400px',
          margin: 'auto',
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#999',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle component="div">
        <Typography variant="h6">Contact Us</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          We would love to hear from you! Please fill out the form below to send us your feedback or inquiry.
        </DialogContentText>
        <Box mt={2}>
          <TextField
            fullWidth
            margin="normal"
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              if (e.target.value.trim()) {
                setError('');  // Clear the error if the user starts typing
              }
            }}
          />
          {error && (
            <Typography color="error" variant="body2" mt={2}>
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', paddingBottom: '16px' }}>
        <Button
          onClick={handleSendMessage}
          variant="contained"
          color="primary"
          disabled={sending || !message.trim()}  // Disable button if sending or message is empty
          sx={{
            backgroundColor: '#4a90e2',  // Set your custom blue color
              '&:hover': {
                backgroundColor: '#3a78c2',
            },
          }}
        >
          {sending ? 'Sending...' : 'Send Message'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CartDialog = ({ open, onClose, cartItems, calculateTotalPrice, handleRemoveFromCart, user }) => {
  const [loading, setLoading] = useState(false);

  const stripePromise = loadStripe('pk_live_51PmwbjCTNuI9mbCHrqBNnLanmPevKHQvNl7cEtnTJOx9L5odRlcCtuBxvIF0ltxDLQS2cHUbtGChc3LzIH3o4W1i00MyKGqsL3');

  const handleCheckoutClick = async () => {
    if (cartItems.length === 0) {
      return; // No items to checkout
    }
  
    setLoading(true);
  
    try {
      const totalQuantity = cartItems.length;
  
      // Send a request to the backend to create a checkout session
      const response = await axios.post(`${config.backendUrl}/create-checkout-session`, {
        email: user.email,  // Ensure the user's email is passed here
        quantity: totalQuantity,  // Total quantity of items
      });
  
      // Ensure stripePromise is initialized before using it
      const stripe = await stripePromise; 
  
      // Use the session ID from the backend
      const sessionId = response.data.sessionId; // Replace `url` with `sessionId` if needed
  
      if (sessionId) {
        // Redirect the user to the Stripe payment page using the session ID
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          console.error('Stripe Checkout redirection failed:', error);
        }
      } else {
        console.error('No session ID returned from the backend');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '20px',
          padding: '24px',
          textAlign: 'left',
          maxWidth: '800px',
          width: '600px',
          margin: 'auto',
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle
        component="div"
        sx={{
          position: 'relative',
          textAlign: 'center',
          borderBottom: '1px solid #ddd',
          mb: 2,
        }}
      >
        <Typography
          component="h2"
          variant="h6"
          sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', fontSize: '1.5rem', color: '#3a78c2' }}
        >
          Shopping Cart
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: -14,
            right: -1,
            color: '#888',
            '&:hover': {
              backgroundColor: '#f0f0f0',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ flex: 2, mr: 2, maxHeight: '300px', overflowY: 'auto', pr: 1 }}>
          {cartItems.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                padding: '10px',
                borderBottom: '1px solid #f0f0f0',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#f7f7f7',
                },
              }}
            >
              {item.loading ? (
                <CircularProgress sx={{ width: 70, height: 70, mr: 2 }} />
              ) : (
              <Box
                component="img"
                src={`data:image/png;base64,${item.image_base64_watermark || item.image_base64}`}
                alt={`Cart item ${index + 1}`}
                sx={{
                  width: 70,
                  height: 70,
                  borderRadius: '8px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  overflow: 'hidden',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.2)',
                  },
                  mr: 2,
                  objectFit: 'cover',
                }}
              />
              )}
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: '400', color: '#666' }}>
                  Coloring Page {index + 1}
                </Typography>
              </Box>
              <IconButton
          onClick={() => handleRemoveFromCart(item.id)} // Make sure you pass the correct item ID
          sx={{
            color: '#666', // Apply greyish color here
            '&:hover': {
              color: '#333', // Slightly darker color on hover, if desired
            },
          }}
        >
          <DeleteIcon />
        </IconButton>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            flex: 1,
            pl: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'center',
          }}
        >
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }} gutterBottom>
              Order Summary
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: '400', color: '#666' }} gutterBottom>
              Items: {cartItems.length}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleCheckoutClick}
            disabled={loading}
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'space-between',
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '1rem',
            }}
          >
            {loading ? 'Processing...' : 'Checkout'}
            <Box component="span" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
              ${calculateTotalPrice()}
            </Box>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

function App() {
  const [user, setUser] = useState(null);
  const [cartDialogOpen, setCartDialogOpen] = useState(false);
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [cartCount, setCartCount] = useState(0); // State for cart count

  // Load user from localStorage when the app loads
  useEffect(() => {
    const savedUser = JSON.parse(localStorage.getItem('user'));
    if (savedUser) {
      setUser(savedUser);
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);  
    const status = searchParams.get('status');
    const openCart = searchParams.get('open_cart');
    const openProfile = searchParams.get('open_profile_dialog');  // Added this to explicitly check for profile dialog

    // If payment failed and open_cart is true, open the cart dialog
    if (status === 'failure' && openCart === 'true') {
      setCartDialogOpen(true);  // Automatically open the cart dialog
    }

    // If payment succeeded and open_profile_dialog is true, open the profile dialog
    if (status === 'success' && openProfile === 'true') {
      setProfileDialogOpen(true);  // Automatically open the profile dialog
    }
  }, []);  // Run this once on component mount

  useEffect(() => {
    if (user) {  // Only run this effect if the user is logged in
      const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      setCartItems(storedCartItems);
    }
  }, [user]);  

  // Adjust how cart items are stored in localStorage
  useEffect(() => {
    const cartItemsIds = cartItems.map(item => item.id); // Only store IDs or minimal info
    localStorage.setItem('cartItems', JSON.stringify(cartItemsIds));
  }, [cartItems]);

  useEffect(() => {
    if (user) {  // Only run this effect if the user is logged in
      const storedCartCount = parseInt(localStorage.getItem('cartCount')) || 0; // Initialize cart count from local storage
      setCartCount(storedCartCount); // Set cart count in state
      fetchCartItems(); // Fetch the actual cart items from the backend
    }
  }, [user]);
  
  useEffect(() => {
    if (user) {
      localStorage.setItem('cartCount', cartCount); // Keep local storage in sync with state
    }
  }, [cartCount, user]);  
  
  useEffect(() => {
    if (user) {
      axios
        .get(`${config.backendUrl}/get_cart_items`, {
          params: { email: user.email },
        })
        .then((response) => {
          if (response.data && response.data.cart_items) {
            // Ensure each item has an ID
            setCartItems(response.data.cart_items.filter(item => item.id));
          } else {
            setCartItems([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching cart items:', error);
        });
    }
  }, [user]);  
  
  const handleRemoveFromCart = (itemId) => {
    if (!itemId) {
      console.error("Error: item ID is undefined or null.");
      return;
    }
  
    // Instantly remove the item from the state for immediate UI feedback
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.id !== itemId)
    );
  
    // Decrement cart count in state and local storage
    const newCount = cartCount - 1;
    setCartCount(newCount);
    localStorage.setItem('cartCount', newCount); // Update local storage directly here
  
    // Proceed with backend deletion request
    axios
      .post(`${config.backendUrl}/remove_from_cart`, { image_id: itemId })
      .then((response) => {
        console.log(response.data.message);
  
        // Add a 500ms delay before refreshing the cart to avoid the quick flash
        setTimeout(() => {
          // Optionally, you can re-fetch the cart items from the server to ensure synchronization
          fetchCartItems();
        }, 500); // 500ms delay
      })
      .catch((error) => {
        console.error("Failed to remove item from cart:", error);
  
        // Optionally, you can revert the UI change or show an error message if the deletion fails
        setCartItems((prevItems) => [...prevItems, itemId]); // Revert state change if desired
        setCartCount(newCount + 1); // Revert cart count if desired
        localStorage.setItem('cartCount', newCount + 1); // Revert local storage count if desired
      });
  };  

  const handleLoginSuccess = (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    const userData = {
      name: decoded.name,
      email: decoded.email,
      picture: decoded.picture,
    };

    // Save user data in localStorage
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
    setOpenLoginDialog(false);
  };

  const handleLoginError = () => {
    console.log('Login Failed');
  };

  const handleLogout = () => {
    googleLogout();
    setUser(null);
    setCartItems([]); // Clear cart items in state
    setCartCount(0); // Reset cart count in state
    localStorage.removeItem('cartCount'); // Clear cart count in local storage
    handleProfileMenuClose();
  };  

  const fetchCartItems = () => {
    if (user) {
      axios
        .get(`${config.backendUrl}/get_cart_items`, {
          params: { email: user.email },
        })
        .then((response) => {
          if (response.data && response.data.cart_items) {
            setCartItems(response.data.cart_items);
          } else {
            setCartItems([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching cart items:', error);
        });
    }
  };  
  
  useEffect(() => {
    if (user) {  // Only fetch cart items if the user is logged in
      fetchCartItems();
    }
  }, [user]);
    
  const handleCartClick = () => {
    if (user) {
      setCartDialogOpen(true);
    } else {
      setOpenLoginDialog(true);
    }
  };

  const handleCartClose = () => {
    setCartDialogOpen(false);
  };

  const handleProfileIconClick = (event) => {
    if (user) {
      setProfileMenuAnchorEl(event.currentTarget);
    } else {
      setOpenLoginDialog(true);
    }
  };

  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null);
  };

  const handleContactDialogOpen = () => {
    setOpenContactDialog(true);
    handleProfileMenuClose();
  };

  const handleContactDialogClose = () => {
    setOpenContactDialog(false);
  };

  const handleProfileDialogOpen = () => {
    setOpenProfileDialog(true);
    handleProfileMenuClose();
  };

  const handleProfileDialogClose = () => {
    setOpenProfileDialog(false);
  };

  const addToCart = (item) => {
    setCartItems([...cartItems, item]);
  
    // Update cart count in state and local storage
    const newCount = cartCount + 1;
    setCartCount(newCount);
    localStorage.setItem('cartCount', newCount); // Update local storage directly here
  };

  const handleCloseDialog = () => {
    setOpenLoginDialog(false);
  };

  const triggerLogin = () => {
    setOpenLoginDialog(true);
  };

  // Calculate total price function
  const calculateTotalPrice = () => {
    const itemCount = cartItems.length;
    const totalPrice = itemCount * 0.99; // Fixed price of $0.99 per item
    return totalPrice.toFixed(2);
  };

  return (
    <GoogleOAuthProvider clientId="726996849049-0vuvq9pc40ah0ige6d1to8rtmtn4jpcu.apps.googleusercontent.com">
      <ThemeProvider theme={theme}>
        <Router>
          <div className="App">
          <AppBar position="static" sx={{ backgroundColor: theme.palette.primary.dark }}>
            <Toolbar>
              {/* Wrapper Box to Align Image and Text */}
              <Box display="flex" alignItems="center">
                {/* Image in Front of Text */}
                <Box
                  component="img"
                  src="/example_images/file.png"  // Replace with your image path
                  alt="Logo"
                  sx={{
                    width: 42,                    // Adjust width
                    height: 35,                   // Adjust height
                    mr: 1,                        // Margin right for spacing
                    filter: 'url(#outline-filter)', // Apply the SVG filter for outline
                  }}
                />
                {/* Styled Text */}
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'CupCakes, sans-serif', // Applying the CupCakes font
                    fontWeight: 'bold',
                    letterSpacing: '2px',
                    color: '#ffffff',
                  }}
                >
                  MyColoringPic
                </Typography>
              </Box>

              <Box sx={{ flexGrow: 1 }} /> {/* Filler to push items to the right */}

              {/* Cart and Profile Icons */}
              <IconButton color="inherit" onClick={handleCartClick} sx={{ ml: 2 }}>
              <Badge badgeContent={cartItems.length} color="secondary">
              <ShoppingCartIcon sx={{ fontSize: 25 }} />
              </Badge>
              </IconButton>
              <IconButton color="inherit" onClick={handleProfileIconClick} sx={{ ml: 2 }}>
                {user ? (
                  <Avatar
                    alt={user.name}
                    src={user.picture}
                    sx={{
                      border: '2px solid white',
                      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                      width: 30,
                      height: 30,
                    }}
                  />
                ) : (
                  <AccountCircleIcon sx={{ fontSize: 30 }} />
                )}
              </IconButton>
              <Menu
                anchorEl={profileMenuAnchorEl}
                open={Boolean(profileMenuAnchorEl)}
                onClose={handleProfileMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  onClick={handleProfileDialogOpen}
                  sx={{
                    typography: 'body1',
                    fontFamily: 'Roboto, sans-serif',
                    color: '#555',
                  }}
                >
                  <AccountCircleOutlinedIcon sx={{ mr: 1, color: '#555', fontSize: 20 }} /> Profile
                </MenuItem>
                <MenuItem
                  onClick={handleContactDialogOpen}
                  sx={{
                    typography: 'body1',
                    fontFamily: 'Roboto, sans-serif',
                    color: '#555',
                  }}
                >
                  <MessageOutlinedIcon sx={{ mr: 1, color: '#555', fontSize: 20 }} /> Contact Us
                </MenuItem>
                <MenuItem
                  onClick={handleLogout}
                  sx={{
                    typography: 'body1',
                    fontFamily: 'Roboto, sans-serif',
                    color: '#555',
                  }}
                >
                  <LogoutOutlinedIcon sx={{ mr: 1, color: '#555', fontSize: 20 }} /> Log out
                </MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>

            {/* Cart Dialog */}
            <CartDialog
              open={cartDialogOpen}
              onClose={handleCartClose}
              cartItems={cartItems}
              calculateTotalPrice={calculateTotalPrice}
              handleRemoveFromCart={handleRemoveFromCart} // Pass the function as a prop
              user={user}  // Pass the user object as a prop
            />

            <Container maxWidth="lg" className="main-container" sx={{ mt: 0.1, mb: 0.1 }}>
              <Routes>
                <Route path="/" element={<LandingPage user={user} addToCart={addToCart} onLogin={triggerLogin} fetchCartItems={fetchCartItems} />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Container>
            <footer
              className="footer"
              style={{
                backgroundColor: '#ffffff',
                color: '#333',
                boxShadow: '0px -1px 10px rgba(0, 0, 0, 0.1)',
                padding: '10px 0',
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" px={4}>
                <Typography variant="body2" color="textSecondary" align="left">
                  {'Built with ❤️ by Jip Kroef and Maarten Stork'}
                </Typography>
                <Box display="flex" alignItems="center" pr={7}>
                  <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                    Share it:
                  </Typography>
                  <Box display="flex">
                    <Box mx={1}>
                      <FacebookShareButton url={window.location.href}>
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                    </Box>
                    <Box mx={1}>
                      <TwitterShareButton url={window.location.href}>
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                    </Box>
                    <Box mx={1}>
                      <TumblrShareButton url={window.location.href}>
                        <TumblrIcon size={32} round />
                      </TumblrShareButton>
                    </Box>
                    <Box mx={1}>
                      <RedditShareButton url={window.location.href}>
                        <RedditIcon size={32} round />
                      </RedditShareButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </footer>
          </div>
        </Router>
      </ThemeProvider>

      {/* Login Dialog */}
      <Dialog
        open={openLoginDialog}
        onClose={handleCloseDialog}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '20px',
            padding: '24px',
            textAlign: 'center',
            maxWidth: '400px',
            margin: 'auto',
          },
        }}
      >
        <IconButton
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#999',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle component="div">  
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
            <AccountCircleIcon sx={{ fontSize: 64, color: '#3a78c2' }} />
          </Box>
          <Typography component="h2" variant="h6"> 
            Welcome to MyColoringPic!
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ minHeight: '150px' }}>
          <DialogContentText>
            Sign in for unlimited access and the ability to save your coloring pages from any device.
          </DialogContentText>
          <Box mt={3} sx={{ display: 'flex', justifyContent: 'center' }}>
            <GoogleLogin
              onSuccess={handleLoginSuccess}
              onError={handleLoginError}
              ux_mode="popup"
            />
          </Box>
        </DialogContent>
      </Dialog>

      {/* Contact Us Dialog */}
      <ContactUsDialog
        open={openContactDialog}
        onClose={handleContactDialogClose}
        userEmail={user ? user.email : ''}
      />

      <ProfileDialog
        open={openProfileDialog}
        onClose={handleProfileDialogClose}
        userName={user ? user.name : ''}
        userPicture={user ? user.picture : ''}
        userEmail={user ? user.email : ''}
      />
    </GoogleOAuthProvider>
  );
}

export default App;
